import React from 'react';

export default function WorkingCanvas(props){

    const {image, livePrediction, capturePoint, canvasRef, canvas_name, opacity, toggleImage, url, person_id, sizeX, sizeY, capturePointOutside, containerRef} = props;
    return         <div className="container" onClick={capturePointOutside} ref={containerRef} style={{padding: "20px", border: "1px solid black"}}>
    <img
      src={image}
      id="image"
      style={{ display: "none", border: "1px solid black" }}
    ></img>
    <img
      src={livePrediction}
      className="frame item"
      style={{
        zIndex: -1,
        width: sizeX,
        height: sizeY,
        opacity: `${(opacity + 5) * 0.1}`,
        top: "20px",
        left: "20px",
        border: "1px solid black"
      }}
      id="image1"
    ></img>
    <canvas
      className="frame item"
      id={canvas_name}
      onClick={capturePoint}
      ref={canvasRef}
      style={{
        position: "absolute",
        top: "20px",
        left: "20px",
        zIndex: "2",
        border: "1px solid black"
      }}
    />
    <img
      height="800px"
      width="600px"
      className="item"
      src={
        !props.toggleImage
          ? props.url
          : `https://storage.cloud.google.com/chan_helper/ashish/relabeling/TopCloth_coloured/${props.person_id}_topImage.png`
      }
      alt="No image in database"
      id="storage_image"
      style={{
        top: "20px",
        left: "20px",
        zIndex: "0",
        opacity: `${opacity * 0.1}`,
        border: "1px solid black"
      }}
    />
  </div>
}