import gql from "graphql-tag";
import moment from "moment";

export const GET_IMAGE_URL = gql`
  query MyQuery($id: Int!) {
    silhouette(where: {id: {_eq: $id}}) {
        isLabelled
        id
        is_na
        reason_for_na
        person_id
        silhouette_seg
        background_seg
        worst_case_labelled
        prediction_type
    }
  }
`;

export const UPDATE_PERSON = gql`
  mutation MyMutation(
    $id: Int!,
    $name: String,
    $seconds_spent: Int,
    $background_seg: String,
    $silhouette_seg: String,
    $last_updated: timestamptz,
    $worst_case_labelled: String,
    $prediction_type: String,
  ) {
    update_silhouette(
      where: { id: { _eq: $id } }
      _set: { 
          background_seg:$background_seg,
          silhouette_seg: $silhouette_seg,
          isLabelled: "Labelled", 
          name: $name,
          seconds_spent: $seconds_spent,
          is_na: "No",
          reason_for_na: "No",
          last_updated: $last_updated,
          worst_case_labelled:$worst_case_labelled,
          prediction_type: $prediction_type
        }
    ) {
      affected_rows
    }
  }
`;

export const allParts = [
{
  variable: "background_seg",
  color: "rgba(185, 185, 185, 255)",
  name: "Rest"
},

{
  variable: "silhouette_seg",
  color: "rgba(255, 85, 0, 255)",
  name: "Person"
},
]

export const allPartsForAssign = [
  // {
  //   variable: "upper_clothes_seg",
  //   color: "rgba(32, 173, 10, 255)",
  //   name: "T shirt / Shirt"
  // },
  // {
  //   variable: "lower_clothes_seg",
  //   color: "rgba(255, 85, 0, 255)",
  //   name: "Pants / Shorts"
  // },
  // {
  //   variable: "dress_seg",
  //   color: "rgba(136, 10, 117, 255)",
  //   name: "Dress"
  // },
  // {
  //   variable: "skirt_seg",
  //   color: "rgba(162, 0, 220, 255)",
  //   name: "Skirt"
  // },
  // {
  //   variable: "heavy_tops_seg",
  //   color: "rgba(57, 45, 40, 255)",
  //   name: "Jackets/ Sweatshirts/ Hoodies/ Sweaters (Ends above the hips / shorter than coat)",
  //   classes: {
  //     color: 'white',
  //     width: '350px',
  //     height: '80px',
  //     whiteSpace: 'inherit',
  //   },
  //   // desc: "Ends above the hips / shorter than coat",
  //   main_class: {
  //     // border: '2px solid black',
  //     // marginBottom: '5px'
  //   }
  // },
  // {
  //   variable: "coat_seg",
  //   color: "rgba(0, 119, 221, 255)",
  //   name: "Coat",
  //   // desc: "Ends below the hips / Longer than jackets / hoodies / sweatshirts",
  //   classes: {
  //     color: 'white',
  //     width: '350px',
  //     height: '80px',
  //     whiteSpace: 'inherit',
  //   },
  //   extra_info: "Ends below the hips / (Longer than jackets / hoodies / sweatshirts)",
  //   extra_classes: {
  //     breakWord: 'none'
  //   },
  //   main_class: {
  //     // border: '2px solid black'
  //   }
  // },
]

export const selectOptionsLabels = [
  {
      variable : "hat_seg",
      color: "#ff5500",
      name: "Hat"
  },
  {
      variable: "hair_seg",
      color: "#ffaa00",
      name: "Hair"
  },
  {
      variable: "gloves_seg",
      color: "#aa0033",
      name: "Gloves"
  },
  {
      variable: "sunglasses_seg",
      color: "#cccccc",
      name: " Sunglasses"
  },
  {
    variable: "socks_seg",
    color: "#cccccc",
    name: "Socks"
  },
  {
    variable: "torso_skin_seg",
    color: "#cccccc",
    name: "Torso skin"
  },
  {
    variable: "scarf_seg",
    color: "#cccccc",
    name: "Scarf"
  },
  {
    variable: "face_seg",
    color: "#cccccc",
    name: "Face"
  },
  {
    variable: "left_arm_seg",
    color: "#cccccc",
    name: "Left Arm"
  },
  {
    variable: "right_arm_seg",
    color: "#cccccc",
    name: "Right arm"
  },
  {
    variable: "right_leg_seg",
    color: "#cccccc",
    name: "Right leg"
  },
  {
    variable: "left_shoe_seg",
    color: "#cccccc",
    name: "Left shoe",
  },
  {
    variable: "right_shoe_seg",
    color: "#cccccc",
    name: "Right shoe"
  },
  {
    variable: "rest_seg",
    color: "#cccccc",
    name: "Rest"
  },
  
  ]

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  silhouette_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

export const UPDATE_PERSON_NA = gql`
  mutation MyMutation(
    $id: Int!,
    $is_na: String,
    $reason_for_na: String!,
    $isLabelled: String!,
    $last_updated: timestamptz,
    $worst_case_labelled: String,
    $upper_clothes_assigned: String,
    $lower_clothes_assigned: String,
    $dress_assigned: String,
    $skirt_assigned: String,
    $heavy_tops_assigned: String,
    $coat_assigned: String,
  ){
      update_silhouette(
        where: { id: { _eq: $id } }
        _set: {
          isLabelled: $isLabelled,
          is_na: $is_na,
          reason_for_na: $reason_for_na
          last_updated: $last_updated
          worst_case_labelled: $worst_case_labelled
        }
      ) {
        affected_rows
        returning {
          is_na
        }
      }
    }
`;

// export const WORST_CASE_QUERY = gql`
// query  MyQuery($id: Int!) {
//   grapy_final(order_by: {id: asc}, where: {worst_case_labelled: {_eq: "Not Labelled"}, id: {_gte: $id}}, limit: 2) {
//     id
//   }
// }
// `;

export const GET_WORSTCASE_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!, $worst_case_date: String!) {
  silhouette_aggregate(distinct_on: id, where: {id: {_gte: $startId, _lte: $endId}, worst_case_date: {_eq: $worst_case_date}}) {
    aggregate {
      count
    }
    nodes {
      id
      is_na
      reason_for_na
      worst_case_labelled
    }
  }
}
`;

export const WORST_CASE_QUERY = gql`
query  MyQuery($id: Int!, $worst_case_date: String) {
  silhouette_final(order_by: {id: asc}, where: {worst_case_date: {_eq: $worst_case_date}, id: {_gte: $id}}, limit: 2) {
    id
  }
}
`;


export const GET_NA_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  silhouette_aggregate(distinct_on: id, where: {is_na: {_eq: "Yes"}, isLabelled: {_eq: "Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
      reason_for_na
    }
  }
}
`;

export const PRED_GOOD_AVAILABLE = true

export const TABLE = "silhouette"

export const OUTPUT_BACKGROUND = 'https://storage.googleapis.com/naman-bucket/dataset/newpersons/'

export const OUTPUT_BACKGROUND_EXT = '.jpg'

export const PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data-dumps/refined_grapy_15_part/`

export const PREDICTION_EXT = `.png`

export const PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/person_sil/`

export const PREDICTION_REVIEW = `https://storage.cloud.google.com/labelling-tools-data/data-dumps/refined_grapy_15_part_final_review/`

// export const PREDICTION_EXT = `.png`