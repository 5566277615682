import React from "react";
import { Image, Box, Button, Radio, RadioGroup } from "@chakra-ui/core";
import { useState, useEffect } from "react";
import { FormControl, FormLabel, Select } from "@chakra-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import "./index.css";
import ModelOutput from "../OutputCorrector";
import { PREDICTION, PREDICTION_EXT, TABLE, PREDICTION_FINAL, PREDICTION_REVIEW, PREDICTION_REVIEW_CHAN } from "../main";
import CoatW from '../../images/longcoatW.jpg';
import coatM from  '../../images/coat.jpg'
import dressW from '../../images/dressW.jpg'
import jacketW from '../../images/jacketW.webp';
import jacket from '../../images/jacket.webp'

function Home(props) {
  const { person_data } = props;

  const [showDropdown, setShowDropDown] = useState(false);
  const [edit, setEdit] = useState(true);
  const [picData, setPicData] = useState();
  const [extraPicData, setExtraPicData] = useState();
  const [toggleImage, setToggleImage] = useState(false);
  const [loadImage, setLoadImage] = useState('prediction')

  useEffect(() => {
    setLoadImage(person_data.prediction_type ? person_data.prediction_type: 'prediction')
  },[person_data])
  // const onInputChange = (e) => {
  //   setFormFields({ ...formFields, [e.target.name]: e.target.value });
  // };

  const linkChange = (link) => {
    if(person_data && person_data.id){
      link = `https://storage.cloud.google.com/labelling-tools-data/person_images_combined_white_padding/${person_data.person_id}.png`;
    }
    if (link === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
      }/o/${link
        .substring(34 + link.split("/")[3].length)
        .replace(/[/]/g, "%2F")}?alt=media`;
    }
  };

  const linkChange_id = (id) => {
    let link = ``;
    if(person_data && person_data['id']){
      link = `${PREDICTION_FINAL}${id}${PREDICTION_EXT}`
    }
    if (id === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
      }/o/${link
        .substring(34 + link.split("/")[3].length)
        .replace(/[/]/g, "%2F")}?alt=media`;
    }
  };

  return (
    <div>
    <div style={{ display: "flex", padding: "0px 10px" }}>

      <Box>
        <div style={{ display: "flex", justifyContent: 'center' }}>
          <div style={{ padding: "5px 0px" }}>
            The image is {person_data.isLabelled},  Minor Refinement : {person_data.worst_case_labelled}
            {true && person_data && <RadioGroup onChange={e => setLoadImage(e.target.value)} value={loadImage} style={{ display: 'flex', padding: '0px 5px' }}>
                <Radio value={"prediction"} style={{ margin: '0px 5px' }}>Use Prediction</Radio>
                <Radio value={"scratch"} style={{ margin: '0px 5px' }}>Start from scratch</Radio>
              </RadioGroup>}
          </div>
          <div>
          </div>
        </div>
        <div style={{lineHeight: '40px', textAlign: 'left', display: 'flex'}}>
          <div style={{width: '80px', backgroundColor:'rgb(158, 0, 89)', height: '40px'}}></div>
          <div style={{width: '80px', backgroundColor:'rgb(170, 255, 85)', height: '40px'}}></div>
          <div style={{width: '80px', backgroundColor:'rgb(0, 85, 0)', height: '40px'}}></div>
            LEFT & RIGHT
          <div style={{width: '80px', backgroundColor:'rgb(0, 255, 255)', height: '40px'}}></div>
          <div style={{width: '80px', backgroundColor:'rgb(154, 3, 30)', height: '40px'}}></div>
          <div style={{width: '80px', backgroundColor:'rgb(255, 170, 0)', height: '40px'}}></div>
        </div>
        {true && (
          <div style={{ display: "flex" }}>
            <div>
              <ModelOutput
                image={
                  person_data &&
                  person_data.person_id &&
                  linkChange(person_data.person_id)
                }
                new_image={
                  loadImage=='prediction' ? person_data &&
                  person_data.person_id &&
                  linkChange_id(person_data.person_id) : 
                  linkChange_id(0)
                  // person_data.torso_segmentation
                }
                load={true}
                isOccluded={true}
                pred_decider={"No"}
                setPicData={setPicData}
                id={person_data.id}
                canvas_name={"canvas_1"}
                url={
                  person_data &&
                  person_data.person_id &&
                  linkChange(person_data.person_id)
                }
                nextImage={props.nextImage}
                person_id={person_data && person_data.person_id}
                setToggleImage={setToggleImage}
                toggleImage={toggleImage}
                loadImage={loadImage}
                name={props.props.match.params.name}
              />
            </div>
          </div>
        )}
      </Box>
    </div>
    <div style={{
      margin: '90px 10px 10px 10px',
      position: "relative",
      // left: (parseInt(LEFT_POSITION.split("px")[0]) + 620) + "px",
      // top: "-800px",
      left: "-600px"
      }} >
      <h2>For Reference, to distinguish between coat, dress and jackets</h2>
      <div style={{display: 'flex',gridRow: '1'}} className={"examples"}>
        <div>
          <h2>Coat for women</h2>
          <img src={CoatW} />
        </div>
        <div>
          <h2>Coat for men</h2>
          <img src={coatM} />
        </div>
        <div>
          <h2>Dress for women</h2>
          <img src={dressW} />
        </div>
        <div>
          <h2>Jacket for men</h2>
          <img src={jacket} />
        </div>
        <div>
          <h2>Jacket for women</h2>
          <img src={jacketW} />
        </div>
      </div>
    </div>
    </div>
  );
}

export default Home;
