import React, { useEffect, useState } from "react";
import { Input, FormControl, FormLabel, Button } from "@chakra-ui/core";
// import { GET_PRODUCT_ID_HELPER } from "../api/home";
import { useQuery } from "@apollo/react-hooks";
import { GET_WORSTCASE_UNLABELLED_IDS, TABLE } from "../main";

let na_hash = {
  "No": "No",
  "black_white": "Black And white",
  "back_side_pose": "Back/Side Pose (90&deg; and more)",
  "slightly_side_pose": "SLightly Side Pose ( 45&deg; to less than 90&deg)",
  "weird_pose": "Weird Pose (Sitting, Weirdly Bent)",
  "half_cut": "Half cut image of person",
  "necklace_present": "Body Occluded by necklace",
  "occluded_image": "Body Occluded by any object, accessory",
  "cloth_as_accessory": "Wearing Cloth as AnAccessory",
  "multiple_people": "Multiple People",
  "no_person": "No person in Image",
  "watermark_present": "Watermark over the person"


}

function Helper(props) {
  const [startId, setStartId] = useState(1);
  const [num, setNum] = useState(100000000);
  const [findId, setFindId] = useState(false);
  const [lastId, setLastId] = useState();
  const [len, setLen] = useState();
  const [worstCaseName, setWastCaseName] = useState();

  const { data: ids, loadingIds } = useQuery(GET_WORSTCASE_UNLABELLED_IDS, {
    skip: (!startId && !num && !findId && !worstCaseName),
    onCompleted(data) {
      console.log(data)
    },
    variables: {
      startId: parseInt(startId),
      endId: parseInt(num),
      worst_case_date: worstCaseName,
    }
  })


  const onInputChange = (e) => {
    if (e.target.name == "num") {
      setNum(e.target.value);
    }
    if (e.target.name == "startId") {
      setStartId(e.target.value);
    }
    if (e.target.name == "worstCaseName") {
      setWastCaseName(e.target.value);
    }
  };

  const processData = (data) => {
    let not_labelled = []
    let na = []
    data.map((id, index) => {
      if (id["worst_case_labelled"] == "Not Labelled") {
        not_labelled.push(id)
      }
      if (id["is_na"] == "Yes") {
        na.push(id)
      }
    })
    let not_labelled_count = not_labelled.length

    let naGroup = transformNa(na)


    return (

      <div >
        <div >
          <div><b>Not Labelled Count : {not_labelled_count}</b></div>
          <div style={{ display: "flex", width: '80%', margin: 'auto', flexWrap: 'wrap' }}>
            {not_labelled.map((id, index) => (<div style={{ margin: '10px 20px' }}>{id.id} ({index + 1})</div>))}
          </div>
          
        </div>
        {Object.keys(naGroup).length > 0 &&
          Object.keys(naGroup).map((group) => (
            <div style={{ margin: '40px 20px' }}>
              <b>{na_hash[group] ? na_hash[group] : group}</b>
              <div style={{ display: "flex", width: '80%', margin: 'auto', flexWrap: 'wrap' }}>
                {naGroup[group].map((id) => (<div style={{ margin: '10px 20px' }}>{id}</div>))}
              </div>
            </div>

          ))
        }
      </div>
    )
  }

  console.log(ids)

  const submit = () => {
    setFindId(true);
  };

  useEffect(() => {
    if (loadingIds == false) {
      setFindId(false)
    }
  }, [loadingIds])

  return (
    <div>
      <h3>Unlabelled ids</h3>
      <form style={{ width: "200px", margin: "auto" }}>
        <FormControl>
          <FormLabel htmlFor="worstCaseName">Worst case Name</FormLabel>
          <Input name="worstCaseName" value={worstCaseName} onChange={onInputChange} />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="startId">Start Id</FormLabel>
          <Input name="startId" value={startId} onChange={onInputChange} />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="num">End Id</FormLabel>
          <Input
            name="num"
            placeholder=""
            value={num}
            onChange={onInputChange}
          />
        </FormControl>
        {/* <Button onClick={submit}>FIND</Button> */}
        {/* {lastId && <div>The last Id is {lastId}</div>}
        {len && <div>Number Of Products: {len}</div>} */}
      </form>
      {loadingIds && <div>loading</div>}
      {/* {<div>{ids}</div>} */}
      {ids && ids[TABLE + '_aggregate'] && <div> Total Count : <b>{ids[TABLE + '_aggregate']['aggregate']['count']}</b></div>}


      {ids && ids[TABLE + '_aggregate'] && ids[TABLE + '_aggregate']['nodes'] && processData(ids[TABLE + '_aggregate']['nodes'])}
    </div>
  );
}

function transformNa(pair) {
  let h = {}
  for (var i = 0; i < pair.length; i += 1) {
    if (h[pair[i]["reason_for_na"]] === undefined) {
      h[pair[i]["reason_for_na"]] = [pair[i]["id"]]
    } else {
      h[pair[i]["reason_for_na"]].push(pair[i]["id"])
    }
  }
  console.log(h)
  return h
}

export default Helper;
