import React, { useEffect, useState, useRef } from "react";
import ModelPrediction from "./newModel";
import MapPoints from "../MapPoints";
import MultipleMapPoints from "../MultipleMapPoints";
import "./index.css";

const App = (props) => {
  const {
    image,
    load,
    isOccluded,
    pred_decider,
    setPicData,
    product_id,
    image_id,
    extraPic,
    canvas_name,
    new_image,
    id,
    url,
    nextImage,
    person_id,
    toggleImage,
    setToggleImage,
    loadImage, 
    name
  } = props;  

  return (<MultipleMapPoints
    image={new_image}
    load={false}
    setPicData={setPicData}
    product_id={product_id}
    image_id={image_id}
    id={id}
    url={url}
    nextImage={nextImage}
    canvas_name = {canvas_name}
    extraPic={extraPic}
    original_image = {image}
    person_id={person_id}
    toggleImage = {toggleImage}
    setToggleImage = {setToggleImage}
    loadImage = {loadImage}
    name = {name}
  />)
};

export default App;
