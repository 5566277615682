import React, { useEffect, useState, useRef } from "react";
import { Radio, RadioGroup, Button, Select } from "@chakra-ui/core";

export   const checkConnectionAndFillWithSome = (points, activeIndex,activeVariable, lastPoint, showSave, allParts, ignorePart, setIgnorePart) => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        return (
          <div>
            <div style={{ width: "300px", whiteSpace: "break-spaces" }}>
              IGNORE
            </div>
            <div style={{ width: "300px", whiteSpace: "break-spaces" }}>
              <Select
                value={ignorePart}
                onChange={(e) => setIgnorePart(e.target.value)}
              >
                <option value={""}>None</option>
                {allParts.map((op) => {
                  if (op["variable"] != activeVariable) {
                    if (op["variable"] !== "background") {
                      return (
                        <option
                          style={{ backgroundColor: op["color"] }}
                          value={op["color"]}
                        >
                          {op["name"]}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          style={{
                            backgroundColor: "rgba(0,0,0,255)",
                            color: "white",
                          }}
                          value={"rgba(0,0,0,255)"}
                        >
                          {op["name"]}
                        </option>
                      );
                    }
                  }
                  return null;
                })}
              </Select>
            </div>
          </div>
        );
      }
  };

  export   const checkConnectionAndFillWithRest = (points, activeIndex, lastPoint, showSave, fillRest) => {
    if (points[activeIndex - 1].length > 2)
      if (points[activeIndex - 1][0] == lastPoint || showSave) {
        return (
          <Button className="" onClick={fillRest}>
            <div style={{ width: "130px", whiteSpace: "break-spaces" }}>
              FILL BACKGROUND
            </div>
          </Button>
        );
      }
  };

  export   const checkConnection = (points, activeIndex, lastPoint, showSave, saveSegmentedImage) => {
    if (points[activeIndex - 1].length > 2 || showSave) {
      if (
        points[activeIndex - 1][0] == lastPoint ||
        points[activeIndex - 1][0] ==
        points[activeIndex - 1][points[activeIndex - 1].length - 1] ||
        showSave
      ) {
        return (
          <Button className="" onClick={saveSegmentedImage}>
            Save
          </Button>
        );
      }
    }
  };