import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './components/Home';
import Helper from './components/Helper'
import NA from './components/NA'
import WorstCaseHelper from './components/WorstCaseHelper'

const Routes = () => (
  <Router>
    <Route exact path="/name=:name/imageid=:imageid" component={Home} />
    <Route exact path="/helper" component={Helper} />
    <Route exact path="/na-helper" component={NA} />
    <Route exact path="/worst_case_helper" component={WorstCaseHelper} />

    {/* <Route exact path="/admin" component={Admin} /> */}
  </Router>
);

export default Routes;